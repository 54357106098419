import { useState } from 'react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { Container, Title } from '~/components'

import * as styles from './Intro.css'

type Props = {
  title: string
}

export function Intro({ title }: Props) {
  const router = useRouter()
  const [pattern, setPattern] = useState('')

  useEffect(() => {
    let randomPattern
    if (router.asPath.includes('eventkalender')) {
      randomPattern = 'muster3-2x.png'
    } else {
      randomPattern = Math.random() < 0.5 ? 'muster1-2x.png' : 'muster2-2x.png'
    }

    setPattern(randomPattern)
  }, [])

  const withHyphens = router.asPath.includes('/koeche') ? false : true

  return (
    <div className={styles.intro} style={{ backgroundImage: `url(/${pattern})` }}>
      <Container>
        <div className={styles.content}>
          <div className={styles.main}>
            <Title animation hyphens={withHyphens}>
              {title}
            </Title>
          </div>
        </div>
      </Container>
    </div>
  )
}
