import Image from 'next/future/image'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { Container, Link, FacebookIcon, InstagramIcon } from '~/components'

import * as styles from './Footer.css'

import vaduzLogo from '~/assets/vaduz-logo.svg'
import vpbankLogo from '~/assets/vpbank-logo.svg'

type Props = React.ComponentPropsWithoutRef<'div'>

export function Footer({ className, ...props }: Props) {
  const router = useRouter()

  return (
    <footer className={classNames([styles.footer, className])} {...props}>
      <Container>
        <div className={styles.main}>
          <h2 className={styles.title}>Hauptpartner</h2>
          <div className={styles.partnerBank}>
            <div className={styles.sponsor}>
              <a href="https://www.vpbank.com" target="_blank" rel="noreferrer">
                <Image src={vpbankLogo} alt="VP Bank AG" width={196} height={64} className={styles.logoVPBank} />
              </a>
            </div>
            <strong>VP Bank AG</strong>
            <br /> Aeulestrasse 6, 9490 Vaduz
            <br /> <a href="tel:+4232356655">T +423 235 66 55</a>
            <br />
            <br />
            <a href="https://www.vpbank.com" target="_blank" rel="noreferrer">
              www.vpbank.com
            </a>
          </div>

          <div className={styles.partnerVaduz}>
            <div className={styles.sponsor}>
              <a href="https://www.vaduz.li" target="_blank" rel="noreferrer">
                <Image src={vaduzLogo} alt="Gemeinde Vaduz" width={196} height={15} className={styles.logoVaduz} />
              </a>
            </div>
            <strong>Gemeinde Vaduz</strong>
            <br />
            Städtle 6, 9490 Vaduz
            <br />
            <a href="tel:+4232377878">T +423 237 78 78</a>
            <br />
            <br />
            <a href="https://www.vaduz.li" target="_blank" rel="noreferrer">
              www.vaduz.li
            </a>
          </div>

          <div className={styles.menu}>
            <ul className={styles.menuList}>
              <li>
                <Link href="/" className={styles.menuLink} data-active={router.asPath === '/'}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  href="/rathausplatz-events"
                  className={styles.menuLink}
                  data-active={router.asPath.includes('/rathausplatz-events')}
                >
                  Rathausplatz-Events
                </Link>
              </li>
              <li>
                <Link
                  href="/eventkalender"
                  className={styles.menuLink}
                  data-active={router.asPath.includes('/eventkalender')}
                >
                  Eventkalender
                </Link>
              </li>
              <li>
                <Link href="/koeche" className={styles.menuLink} data-active={router.asPath.includes('/koeche')}>
                  Köche
                </Link>
              </li>
              <li>
                <Link
                  href="/restaurants-geschaefte"
                  className={styles.menuLink}
                  data-active={router.asPath.includes('/restaurants-geschaefte')}
                >
                  Restaurants & Geschäfte
                </Link>
              </li>
              <li>
                <Link href="/partner" className={styles.menuLink} data-active={router.asPath.includes('/partner')}>
                  Partner
                </Link>
              </li>
              <li>
                <Link href="/info" className={styles.menuLink} data-active={router.asPath.includes('/info')}>
                  Info
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.share}>
            Bleib informiert:
            <ul className={styles.shareList}>
              <li>
                <a
                  href="https://www.instagram.com/genussfestival_vaduz/"
                  className={styles.shareLink}
                  aria-label="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon className={styles.shareIcon} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Genussfestival-Vaduz-105210368954470"
                  className={styles.shareLink}
                  aria-label="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className={styles.shareIcon} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className={styles.legal}>
        <div className={styles.legalMain}>
          <div className={styles.legalLeft}>
            © Genussfestival Vaduz <span className={styles.legalSpacer}>–</span> Alle Rechte vorbehalten.
          </div>
          <div className={styles.legalRight}>
            <ul className={styles.legalList}>
              <li>
                <Link
                  href="/impressum"
                  className={classNames([styles.legalLink, router.asPath === '/impressum' && styles.legalLinkActive])}
                >
                  Impressum
                </Link>
              </li>
              <li>
                <Link
                  href="/disclaimer"
                  className={classNames([styles.legalLink, router.asPath === '/disclaimer' && styles.legalLinkActive])}
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  href="/datenschutz"
                  className={classNames([styles.legalLink, router.asPath === '/datenschutz' && styles.legalLinkActive])}
                >
                  Datenschutz
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
