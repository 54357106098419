import { useState } from 'react'
import Image from 'next/image'

import { Image as ImageType } from '~/lib/storyblok'
import { getType, Type } from '~/storyblok/datasources'
import { Link, Button } from '~/components'

import * as styles from './Item.css'
import classNames from 'classnames'

type Props = React.ComponentPropsWithoutRef<'div'> & {
  image: ImageType
  type?: Type
  meta?: string
  title: string
  teaser?: string
  href: string
  showButton?: boolean
  showIcon?: boolean
}

export function Item({ image, type, meta, teaser, showButton = true, showIcon = true, title, href }: Props) {
  const [isActive, setIsActive] = useState(false)

  return (
    <Link
      href={href}
      className={styles.item}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {showIcon && type && (
        <div className={styles.type}>
          <img src={`/icons/${type}.svg`} alt="" className={styles.typeIcon} />
          <span className={classNames([styles.typeName, isActive && styles.typeNameActive])}>{getType(type)}</span>
        </div>
      )}
      <div className={styles.media}>
        <div className={styles.canvas}>
          <div className={styles.image}>
            <Image
              src={image.url}
              alt={image.alt}
              width={image.width}
              height={image.height}
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>
      </div>
      <div className={styles.meta}>{meta}</div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.teaser}>{teaser}</div>
      {showButton && (
        <div className={styles.action}>
          <Button active={isActive}>Mehr</Button>
        </div>
      )}
    </Link>
  )
}
