import Image from 'next/future/image'

import { Container } from '~/components'

import * as styles from './Header.css'

import vaduzLogo from '~/assets/vaduz-logo.svg'
import vpbankLogo from '~/assets/vpbank-logo.svg'

export function Header() {
  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.content}>
          <div className={styles.logos}>
            <a href="https://www.vpbank.com" target="_blank" rel="noreferrer" className={styles.logo}>
              <Image src={vpbankLogo} alt="VP Bank AG" width={134} height={44} />
            </a>
            <a href="https://www.vaduz.li" target="_blank" rel="noreferrer" className={styles.logo}>
              <Image src={vaduzLogo} alt="Gemeinde Vaduz" width={158} height={12} className={styles.logoVaduz} />
            </a>
          </div>
          <span className={styles.info}>1. — 11. September 2022</span>
        </div>
      </Container>
    </header>
  )
}
